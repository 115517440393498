import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { Fragment, useState } from 'react';

import BaseModal from 'UI/BaseModal/BaseModal';
import RootPopover, {
  EmptyNotificationWrapper,
  MessageIndicator,
  PopoverContent,
  PopoverMessage,
  PopoverTitle,
} from './NotificationPopover.styled';
import { SmallText, Text } from 'UI';

import { useUser } from 'store/slices/user';

import NotificationIcon from 'assets/images/NotificationIcon';

const NotificationPopover = () => {
  const { notifications, deleteNotification, getUserNotifications } = useUser();
  const [openModal, setOpenModal] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);

  const hasNotifications = notifications?.length > 0;

  const handleOpenModal = () => {
    setOpenModal((prevState) => !prevState);
  };

  const handleCloseModal = () => {
    const payload = { messagesUUIDs: [currentNotification?.messageUUID] };
    deleteNotification(payload).then(() => getUserNotifications());
    setOpenModal(false);
  };

  const handleCurrentNotification = (notification) => () => {
    setCurrentNotification(notification);
    handleOpenModal();
  };

  const renderNotificaitons = (
    <Fragment>
      {notifications?.map((notification) => (
        <PopoverMessage
          onClick={handleCurrentNotification(notification)}
          key={notification?.messageUUID}
        >
          <SmallText sx={{ fontSize: '13px' }}>
            {notification?.message}
          </SmallText>
          <MessageIndicator />
        </PopoverMessage>
      ))}
    </Fragment>
  );

  const renderEmptyNotifications = (
    <EmptyNotificationWrapper>
      <Text>You don't have any notifications</Text>
    </EmptyNotificationWrapper>
  );

  return (
    <Fragment>
      <PopupState variant="popover" popupId="notification-popover">
        {(popupState) => (
          <div>
            <NotificationIcon
              {...bindTrigger(popupState)}
              active={hasNotifications}
            />
            <RootPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <PopoverTitle>
                <Text>Notifications</Text>
              </PopoverTitle>

              <PopoverContent>
                {hasNotifications && renderNotificaitons}
                {!hasNotifications && renderEmptyNotifications}
              </PopoverContent>
            </RootPopover>
          </div>
        )}
      </PopupState>

      <BaseModal
        open={openModal}
        onClose={handleCloseModal}
        onSubmit={handleCloseModal}
        title="Congratulations!"
        cancel={false}
      >
        <Text sx={{ fontSize: '16px', textAlign: 'center' }}>
          {currentNotification?.message}
        </Text>
      </BaseModal>
    </Fragment>
  );
};

export default NotificationPopover;
