import { createSlice } from '@reduxjs/toolkit';

import REDUX_USER, {
  getBadgeMeAction,
  getNotifications,
  getStatisticMeAction,
  updateStatisticMeAction,
  getSubscriptionMeAction,
  getUserMeAction,
} from './actions';

const initialState = {
  loading: 'not loaded',
  userInfo: {},
  userSubscription: {},
  statisticLoader: 'not loaded',
  userStatistic: {},
  badgeLoader: 'not loaded',
  userBadge: {},
  notifications: [],
};

const userSlice = createSlice({
  name: REDUX_USER,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.notifications = action.payload;
    });
    builder.addCase(getNotifications.rejected, (state) => {
      state.loading = 'error';
      state.notifications = {};
    });

    builder.addCase(getUserMeAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(getUserMeAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.userInfo = action.payload;
    });
    builder.addCase(getUserMeAction.rejected, (state) => {
      state.loading = 'error';
      state.userInfo = {};
    });

    builder.addCase(getSubscriptionMeAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(getSubscriptionMeAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.userSubscription = action.payload;
    });
    builder.addCase(getSubscriptionMeAction.rejected, (state) => {
      state.loading = 'error';
      state.userSubscription = {};
    });

    builder.addCase(getStatisticMeAction.pending, (state) => {
      state.statisticLoader = 'loading';
    });
    builder.addCase(getStatisticMeAction.fulfilled, (state, action) => {
      state.statisticLoader = 'loaded';
      state.userStatistic = action.payload.data;
    });
    builder.addCase(getStatisticMeAction.rejected, (state) => {
      state.statisticLoader = 'error';
      state.userStatistic = {};
    });

    builder.addCase(updateStatisticMeAction.pending, (state) => {
      state.statisticLoader = 'loading';
    });
    builder.addCase(updateStatisticMeAction.fulfilled, (state, action) => {
      state.statisticLoader = 'loaded';
      state.userStatistic = action.payload.data;
    });
    builder.addCase(updateStatisticMeAction.rejected, (state) => {
      state.statisticLoader = 'error';
      state.userStatistic = {};
    });

    builder.addCase(getBadgeMeAction.pending, (state) => {
      state.badgeLoader = 'loading';
    });
    builder.addCase(getBadgeMeAction.fulfilled, (state, action) => {
      state.badgeLoader = 'loaded';
      state.userBadge = action.payload.data;
    });
    builder.addCase(getBadgeMeAction.rejected, (state) => {
      state.badgeLoader = 'error';
      state.userBadge = {};
    });
  },
});

export default userSlice.reducer;
