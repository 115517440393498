import { createSlice } from '@reduxjs/toolkit';

import REDUX_INFO, {
  getBadgeTypesAction,
  getChatSessionIdAction,
  getBadgeRequirementsAction,
  getJurisdictionAction,
  getSubscriptionAction,
} from './actions';

const initialState = {
  loading: 'not loaded',
  subscriptions: [],
  jurisdictions: [],
  badgeTypes: [],
  badgeRequirements: [],
  chatSession: {},
};

const infoSlice = createSlice({
  name: REDUX_INFO,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(getSubscriptionAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.subscriptions = action.payload;
    });
    builder.addCase(getSubscriptionAction.rejected, (state) => {
      state.loading = 'error';
      state.subscriptions = [];
    });

    builder.addCase(getJurisdictionAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(getJurisdictionAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.jurisdictions = action.payload;
    });
    builder.addCase(getJurisdictionAction.rejected, (state) => {
      state.loading = 'error';
      state.jurisdictions = [];
    });

    builder.addCase(getBadgeTypesAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(getBadgeTypesAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.badgeTypes = action.payload;
    });
    builder.addCase(getBadgeTypesAction.rejected, (state) => {
      state.loading = 'error';
      state.badgeTypes = [];
    });

    builder.addCase(getBadgeRequirementsAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(getBadgeRequirementsAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.badgeRequirements = action.payload;
    });
    builder.addCase(getBadgeRequirementsAction.rejected, (state) => {
      state.loading = 'error';
      state.badgeRequirements = [];
    });

    builder.addCase(getChatSessionIdAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(getChatSessionIdAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.chatSession = action.payload;
    });
    builder.addCase(getChatSessionIdAction.rejected, (state) => {
      state.loading = 'error';
      state.chatSession = {};
    });
  },
});

export default infoSlice.reducer;
