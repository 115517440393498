import React from 'react';

import RootPrimaryButton, {
  RootAccentButton,
  RootAccentLoaderButton,
  RootPrimaryLoaderButton,
} from './BaseButton.styled';

export const buttonType = {
  primary: 'primary',
  secondary: 'secondary',
  accent: 'accent',
};

const fontSize = '14px';

const BaseButton = ({
  type,
  label,
  length,
  sx = {},
  onClick,
  loader,
  disabled,
  Icon,
}) => {
  if (type === buttonType.accent)
    return loader ? (
      <RootAccentLoaderButton
        loading
        sx={{
          maxWidth: length,
          width: length ? '100%' : 'unset',
          ...sx,
          fontSize,
        }}
        onClick={onClick}
        disabled={disabled}
      >
        {label}
      </RootAccentLoaderButton>
    ) : (
      <RootAccentButton
        sx={{
          maxWidth: length,
          width: length ? '100%' : 'unset',
          ...sx,
          fontSize,
        }}
        onClick={onClick}
        disabled={disabled}
      >
        {label}
      </RootAccentButton>
    );

  return loader ? (
    <RootPrimaryLoaderButton
      loading
      sx={{
        maxWidth: length,
        width: length ? '100%' : 'unset',
        ...sx,
        fontSize,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </RootPrimaryLoaderButton>
  ) : (
    <RootPrimaryButton
      sx={{
        maxWidth: length,
        width: length ? '100%' : 'unset',
        ...sx,
        fontSize,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {Icon && (
        <Icon
          style={{
            position: 'absolute',
            left: 10,
          }}
        />
      )}
      {label}
    </RootPrimaryButton>
  );
};

const FullwidthButton = ({
  type,
  label,
  sx = {},
  onClick,
  loader = false,
  disabled,
  Icon,
}) => {
  return (
    <BaseButton
      label={label}
      type={type}
      onClick={onClick}
      sx={{ padding: '14px 10px', borderRadius: '24px', ...sx }}
      length="100%"
      loader={loader}
      disabled={disabled}
      Icon={Icon}
    />
  );
};

export { FullwidthButton };
export default BaseButton;
