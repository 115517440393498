import { Controller, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { Box } from '@mui/material';

import BaseModal from 'UI/BaseModal/BaseModal';
import { BaseCheckbox, BaseInput, BaseSelect } from 'UI';

import { STANDARDS_ROUTE, TERMS_AND_CONDITIONS_ROUTE } from 'routes/path';


import { useBadge } from 'store/slices/badge';
import { useUser } from 'store/slices/user';

const Q1List = [
  {
    label: 'Beginner',
    name: 'Beginner',
  },
  {
    label: 'Intermediate',
    name: 'Intermediate',
  },
  {
    label: 'Expert',
    name: 'Expert',
  },
];

const Q2List = [
  {
    label: 'North America',
    name: 'North America',
  },
  {
    label: 'Latin America',
    name: 'Latin America',
  },
  {
    label: 'Western Europe',
    name: 'Western Europe',
  },
  {
    label: 'Eastern Europe & CIS',
    name: 'Eastern Europe & CIS',
  },
  {
    label: 'Asia-Pacific (APAC)',
    name: 'Asia-Pacific (APAC)',
  },
  {
    label: 'Middle East & North Africa (MENA)',
    name: 'Middle East & North Africa (MENA)',
  },
  {
    label: 'Sub-Saharan Africa',
    name: 'Sub-Saharan Africa',
  },
  {
    label: 'South Asia',
    name: 'South Asia',
  },
  {
    label: 'Southeast Asia',
    name: 'Southeast Asia',
  },
  {
    label: 'Oceania',
    name: 'Oceania',
  },
];

const Q3List = [
  {
    label: 'Decentralized Finance (DeFi)',
    name: 'DeFi',
  },
  {
    label: 'Non-Fungible Tokens (NFTs)',
    name: 'NFTs',
  },
  {
    label: 'Decentralized Autonomous Organizations (DAOs)',
    name: 'DAOs',
  },
  {
    label: 'Layer 1 Blockchain Platforms',
    name: 'Layer 1',
  },
  {
    label: 'Layer 2 Scaling Solutions',
    name: 'Layer 2',
  },
  {
    label: 'Gaming and Metaverse',
    name: 'Gaming and Metaverse',
  },
  {
    label: 'Infrastructure Projects',
    name: 'Infrastructure',
  },
  {
    label: 'Social Networks',
    name: 'Social Networks',
  },
  {
    label: 'Tokenized Real Assets',
    name: 'Tokenized Real Assets',
  },
  {
    label: 'Artificial Intelligence',
    name: 'AI',
  },
  {
    label: 'Other',
    name: 'Other',
  },
];


const isBronzeBadge = (badge) => {
  return badge === undefined ;
};

const ClaimBadgeModal = ({ open, onClose, customFunc }) => {
  const { publishBadge } = useBadge();
  const { userBadge, getStatisticMe } = useUser();

  const { getValues, control } = useForm();
  const [errors, setErrors] = useState({
    userDirectoryName: null,
    question: null,
  });
  const [q, setQ] = useState({});

  const [publicDirectory, setPublicDirectory] = useState(false);
  const [standards, setStandards] = useState(false);
  const [terms, setTerms] = useState(false);

  const handleStandardsChange = (event) => {
    const value = event.target.checked;
    setStandards(value);
  };

  const handleTermsChange = (event) => {
    const value = event.target.checked;
    setTerms(value);
  }

  const handlePublishDirectoryChange = (event) => {
    const value = event.target.checked;
    setPublicDirectory(value);
  };

  const handleQuestionChange = (question) => (_event, newInputValue) => {
    setQ({ ...q, [question]: newInputValue });
  };

  const handleSubmit = async () => {
    const fields = { ...getValues() };
    // const newError = { ...errors };

    // if (areAllValuesNull(newError)) {
    const payload = {
      repositoryName: fields.userDirectoryName,
      published: publicDirectory,
      acceptedStandards: standards,
    };
    publishBadge(payload).then(async () => {
      await getStatisticMe();
      onClose();
    });
    // }
  };

  return (
    <BaseModal
      title="Claim Badge"
      open={open}
      onClose={onClose}
      onCancel={onClose}
      disableSubmit={!standards}
      onSubmit={handleSubmit}
      submitLabel="Claim"
    >
      {isBronzeBadge(userBadge?.badgeType) && (
        <Controller
          name="userDirectoryName"
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              label="Directory Name"
              name="userDirectoryName"
              value={value}
              onChange={onChange}
              error={Boolean(errors.userDirectoryName)}
              helperText={errors.userDirectoryName}
              fullWidth
            />
          )}
        />
      )}

      <BaseCheckbox
        label="Public badge directory"
        checked={publicDirectory}
        onChange={handlePublishDirectoryChange}
      />

      {isBronzeBadge(userBadge?.badgeType) && (
        <Box mt="24px" display="flex" flexDirection="column" gap="16px">
          <BaseSelect
            label="How would you assess your level of experience in web3?"
            options={Q1List}
            onChange={handleQuestionChange('q1')}
            value={q?.q1}
          />

          <BaseSelect
            label="How would you assess your level of experience in compliance?"
            options={Q1List}
            onChange={handleQuestionChange('q2')}
            value={q?.q2}
          />

          <BaseSelect
            label="What region are you interested in operating in?"
            options={Q2List}
            onChange={handleQuestionChange('q3')}
            value={q?.q3}
          />

          <BaseSelect
            label="What sector of web3 are you interested in operating in?"
            options={Q3List}
            onChange={handleQuestionChange('q4')}
            value={q?.q4}
          />
        </Box>
      )}

      <BaseCheckbox
        label="Standards"
        linkLabel="(read more)"
        linkRoute={STANDARDS_ROUTE}
        checked={standards}
        onChange={handleStandardsChange}
      />
      <BaseCheckbox
        label="Terms and Conditions"
        linkLabel="(read more)"
        linkRoute={TERMS_AND_CONDITIONS_ROUTE}
        checked={terms}
        onChange={handleTermsChange}
      />
    </BaseModal>
  );
};

export default ClaimBadgeModal;
