import GoogleIcon from '@mui/icons-material/Google';
import Divider from '@mui/material/Divider';

import { RootButtonWrapper } from '../AuthForm/AuthForm.styled';
import { FullwidthButton } from 'UI';
import useCookieConsent from 'hooks/useCookieConsent';


export const GoogleAuth = ({ loader }) => {
  const { marketing, statistics, necessary } = useCookieConsent();
  const state = JSON.stringify({ marketing, statistics, necessary });
  const API_BASE_URL = process.env.REACT_APP_BASE_API_URL || 'http://localhost:8080/api/';
  // const client_id =
  //   '246378658466-pvjfvuvj2pn21nk99nkfeda2jk3trp89.apps.googleusercontent.com';
  const client_id = '264180590728-qfftfj10sv6mop3qo6j8ndrc9r1no676.apps.googleusercontent.com';
  const redirect_uri = `${API_BASE_URL}auth/google/callback`;
  const uri = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirect_uri}&response_type=code&client_id=${client_id}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+openid&access_type=offline&state=${encodeURIComponent(state)}`;
  return (
    <>
      <Divider style={{ marginTop: '36px' }}>Or</Divider>
      <RootButtonWrapper>
        <FullwidthButton
          label={'Connect with Google'}
          onClick={() => window.location.replace(uri)}
          loader={loader}
          sx={{
            backgroundColor: '#11ffee00',
            color: 'black',
            '&:hover': { color: 'white' },
          }}
          Icon={GoogleIcon}
        />
      </RootButtonWrapper>
    </>
  );
};

export default GoogleAuth;
